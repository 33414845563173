import React from "react";
import styled from "styled-components";

const Grid = styled.div`
  ${(props) => props.backgound && `backgound: ${props.backgound};`}
  display: grid;
  grid-template-columns: repeat(${(props) => props.columnMobile ?? 1}, 1fr);
  gap: 0.5em;
  width: 100%;
  margin: ${(props) => (props.padding ? "0 auto" : "2em 0")};
  ${(props) => props.padding && `padding: ${props.padding};`}
  @media only screen and (min-width: 481px) {
    grid-template-columns: repeat(${(props) => props.column ?? 12}, 1fr);
  }
`;

function GridContainer({...props}) {
  return (
    <Grid
      {...props}
    >
      {props.children}
    </Grid>
  );
}

export default GridContainer;
