import React from "react";

const Precarga = () => {
  return (
    <div className="preload">
      <div className="spinner">
        <div className="double-bounce1"></div>
        <div className="double-bounce2"></div>
      </div>
    </div>
  );
};

export default Precarga;
