export default {
  //colors
  "transparent": "transparent",
  "black": "#000",
  "white": "#fff",
  "gray-50": "#FAFAFA",
  "gray-100": "#F5F5F5",
  "gray-200": "#EEEEEE",
  "gray-300": "#E0E0E0",
  "gray-400": "#BDBDBD",
  "gray-500": "#9E9E9E",
  "gray-600": "#757575",
  "gray-700": "#616161",
  "gray-800": "#424242",
  "gray-900": "#212121",
  "blueGray-50": "#ECEFF1",
  "blueGray-100": "#CFD8DC",
  "blueGray-200": "#B0BEC5",
  "blueGray-300": "#90A4AE",
  "blueGray-400": "#78909C",
  "blueGray-500": "#607D8B",
  "blueGray-600": "#546E7A",
  "blueGray-700": "#455A64",
  "blueGray-800": "#37474F",
  "blueGray-900": "#263238",
  "red-50": "#FFEBEE",
  "red-100": "#FFCDD2",
  "red-200": "#EF9A9A",
  "red-300": "#E57373",
  "red-400": "#EF5350",
  "red-500": "#F44336",
  "red-600": "#E53935",
  "red-700": "#D32F2F",
  "red-800": "#C62828",
  "red-900": "#B71C1C",
  "red-A100": "#FF8A80",
  "red-A200": "#FF5252",
  "red-A400": "#FF1744",
  "red-A700": "#D50000",
  "pink-50 ": "#FCE4EC",
  "pink-100": "#F8BBD0",
  "pink-200": "#F48FB1",
  "pink-300": "#F06292",
  "pink-400": "#EC407A",
  "pink-500": "#E91E63",
  "pink-600": "#D81B60",
  "pink-700": "#C2185B",
  "pink-800": "#AD1457",
  "pink-900": "#880E4F",
  "pink-A100": "#FF80AB",
  "pink-A200": "#FF4081",
  "pink-A400": "#F50057",
  "pink-A700": "#C51162",
  "purple-50": "#F3E5F5",
  "purple-100": "#E1BEE7",
  "purple-200": "#CE93D8",
  "purple-300": "#BA68C8",
  "purple-400": "#AB47BC",
  "purple-500": "#9C27B0",
  "purple-600": "#8E24AA",
  "purple-700": "#7B1FA2",
  "purple-800": "#6A1B9A",
  "purple-900": "#4A148C",
  "purple-A100": "#EA80FC",
  "purple-A200": "#E040FB",
  "purple-A400": "#D500F9",
  "purple-A700": "#AA00FF",
  "deepPurple-50": "#EDE7F6",
  "deepPurple-100": "#D1C4E9",
  "deepPurple-200": "#B39DDB",
  "deepPurple-300": "#9575CD",
  "deepPurple-400": "#7E57C2",
  "deepPurple-500": "#673AB7",
  "deepPurple-600": "#5E35B1",
  "deepPurple-700": "#512DA8",
  "deepPurple-800": "#4527A0",
  "deepPurple-900": "#311B92",
  "deepPurple-A100": "#B388FF",
  "deepPurple-A200": "#7C4DFF",
  "deepPurple-A400": "#651FFF",
  "deepPurple-A700": "#6200EA",
  "indigo-50": "#E8EAF6",
  "indigo-100": "#C5CAE9",
  "indigo-200": "#9FA8DA",
  "indigo-300": "#7986CB",
  "indigo-400": "#5C6BC0",
  "indigo-500": "#3F51B5",
  "indigo-600": "#3949AB",
  "indigo-700": "#303F9F",
  "indigo-800": "#283593",
  "indigo-900": "#1A237E",
  "indigo-A100": "#8C9EFF",
  "indigo-A200": "#536DFE",
  "indigo-A400": "#3D5AFE",
  "indigo-A700": "#304FFE",
  "blue-50": "#E3F2FD",
  "blue-100": "#BBDEFB",
  "blue-200": "#90CAF9",
  "blue-300": "#64B5F6",
  "blue-400": "#42A5F5",
  "blue-500": "#2196F3",
  "blue-600": "#1E88E5",
  "blue-700": "#1976D2",
  "blue-800": "#1565C0",
  "blue-900": "#0D47A1",
  "blue-A100": "#82B1FF",
  "blue-A200": "#448AFF",
  "blue-A400": "#2979FF",
  "blue-A700": "#2962FF",
  "lightBlue-50": "#E1F5FE",
  "lightBlue-100": "#B3E5FC",
  "lightBlue-200": "#81D4FA",
  "lightBlue-300": "#4FC3F7",
  "lightBlue-400": "#29B6F6",
  "lightBlue-500": "#03A9F4",
  "lightBlue-600": "#039BE5",
  "lightBlue-700": "#0288D1",
  "lightBlue-800": "#0277BD",
  "lightBlue-900": "#01579B",
  "lightBlue-A100": "#80D8FF",
  "lightBlue-A200": "#40C4FF",
  "lightBlue-A400": "#00B0FF",
  "lightBlue-A700": "#0091EA",
  "cyan-50": "#E0F7FA",
  "cyan-100": "#B2EBF2",
  "cyan-200": "#80DEEA",
  "cyan-300": "#4DD0E1",
  "cyan-400": "#26C6DA",
  "cyan-500": "#00BCD4",
  "cyan-600": "#00ACC1",
  "cyan-700": "#0097A7",
  "cyan-800": "#00838F",
  "cyan-900": "#006064",
  "cyan-A100": "#84FFFF",
  "cyan-A200": "#18FFFF",
  "cyan-A400": "#00E5FF",
  "cyan-A700": "#00B8D4",
  "teal-50": "#E0F2F1",
  "teal-100": "#B2DFDB",
  "teal-200": "#80CBC4",
  "teal-300": "#4DB6AC",
  "teal-400": "#26A69A",
  "teal-500": "#009688",
  "teal-600": "#00897B",
  "teal-700": "#00796B",
  "teal-800": "#00695C",
  "teal-900": "#004D40",
  "green-50": "#E8F5E9",
  "green-100": "#C8E6C9",
  "green-200": "#A5D6A7",
  "green-300": "#81C784",
  "green-400": "#66BB6A",
  "green-500": "#4CAF50",
  "green-600": "#43A047",
  "green-700": "#388E3C",
  "green-800": "#2E7D32",
  "green-900": "#1B5E20",
  "lightGreen-50": "#F1F8E9",
  "lightGreen-100": "#DCEDC8",
  "lightGreen-200": "#C5E1A5",
  "lightGreen-300": "#AED581",
  "lightGreen-400": "#9CCC65",
  "lightGreen-500": "#8BC34A",
  "lightGreen-600": "#7CB342",
  "lightGreen-700": "#689F38",
  "lightGreen-800": "#558B2F",
  "lightGreen-900": "#33691E",
  "lime-50": "#F9FBE7",
  "lime-100": "#F0F4C3",
  "lime-200": "#E6EE9C",
  "lime-300": "#DCE775",
  "lime-400": "#D4E157",
  "lime-500": "#CDDC39",
  "lime-600": "#C0CA33",
  "lime-700": "#AFB42B",
  "lime-800": "#9E9D24",
  "lime-900": "#827717",
  "yellow-50": "#FFFDE7",
  "yellow-100": "#FFF9C4",
  "yellow-200": "#FFF59D",
  "yellow-300": "#FFF176",
  "yellow-400": "#FFEE58",
  "yellow-500": "#FFEB3B",
  "yellow-600": "#FDD835",
  "yellow-700": "#FBC02D",
  "yellow-800": "#F9A825",
  "yellow-900": "#F57F17",
  "amber-50": "#FFF8E1",
  "amber-100": "#FFECB3",
  "amber-200": "#FFE082",
  "amber-300": "#FFD54F",
  "amber-400": "#FFCA28",
  "amber-500": "#FFC107",
  "amber-600": "#FFB300",
  "amber-700": "#FFA000",
  "amber-800": "#FF8F00",
  "amber-900": "#FF6F00",
  "orange-50": "#FFF3E0",
  "orange-100": "#FFE0B2",
  "orange-200": "#FFCC80",
  "orange-300": "#FFB74D",
  "orange-400": "#FFA726",
  "orange-500": "#FF9800",
  "orange-600": "#FB8C00",
  "orange-700": "#F57C00",
  "orange-800": "#EF6C00",
  "orange-900": "#E65100",
  "deepOrange-50": "#FBE9E7",
  "deepOrange-100": "#FFCCBC",
  "deepOrange-200": "#FFAB91",
  "deepOrange-300": "#FF8A65",
  "deepOrange-400": "#FF7043",
  "deepOrange-500": "#FF5722",
  "deepOrange-600": "#F4511E",
  "deepOrange-700": "#E64A19",
  "deepOrange-800": "#D84315",
  "deepOrange-900": "#BF360C",
  "brown-50": "#EFEBE9",
  "brown-100": "#D7CCC8",
  "brown-200": "#BCAAA4",
  "brown-300": "#A1887F",
  "brown-400": "#8D6E63",
  "brown-500": "#795548",
  "brown-600": "#6D4C41",
  "brown-700": "#5D4037",
  "brown-800": "#4E342E",
  "brown-900": "#3E2723",
  //fontSize
  "fontSize-xs": "0.75rem",
  "fontSize-sm": "0.875rem",
  "fontSize-base": "1rem",
  "fontSize-lg": "1.125rem",
  "fontSize-xl": "1.25rem",
  "fontSize-2xl": "1.5rem",
  "fontSize-3xl": "1.875rem",
  "fontSize-4xl": "2.25rem",
  "fontSize-5xl": "3rem",
  "fontSize-6xl": "4rem",
  //fontWeight
  "fontWeight-hairline": 100,
  "fontWeight-thin": 200,
  "fontWeight-light": 300,
  "fontWeight-normal": 400,
  "fontWeight-medium": 500,
  "fontWeight-semibold": 600,
  "fontWeight-bold": "700",
  "fontWeight-extrabold": 800,
  "fontWeight-black": 900,
  //boxShadow
  "boxShadow-xs": '0 0 0 1px rgba(0, 0, 0, 0.05)',
  "boxShadow-sm": '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
  "boxShadow-default": '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
  "boxShadow-md": '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
  "boxShadow-lg": '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
  "boxShadow-xl": '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
  "boxShadow-2xl": '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
  "boxShadow-inner": 'inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)',
  "boxShadow-outline-right": '7px 0px 25px -5px rgba(0,0,0,0.2)',
  "boxShadow-none": 'none',
};


