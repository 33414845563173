import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { theme } from "../styles/index";

const NavLink = styled.div`
  transition: 2s;
  position: relative;
  a {
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #fff;
      border: 1px solid ${theme["gray-400"]};
      display: flex;
      align-items: flex-end;
      padding: 5%;
      opacity: 0;
      transition: 1s;
    }

    &:hover {
      .overlay {
        opacity: 1;
      }
    }
  }
`;

const CardHover = ({ title, image, alt, route }) => {
  return (
    <NavLink className="fade">
      <Link
        to={route}
      >
        <img src={image} alt={alt} />
        <div className="overlay">
          <h3>{title}</h3>
        </div>
      </Link>
    </NavLink>
  );
};

export default CardHover;
