import React from "react";
import styled from "styled-components";
import { theme } from "../styles/index";

//social images
import instagram from "../assets/images/instagram.svg";
import facebook from "../assets/images/facebook.svg";
import pinterest from "../assets/images/pinterest.svg";
import linkedin from "../assets/images/linkedin.svg";

const Social = styled.div`
  display: flex;
  height: 45px;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 1rem 0;
  width: 150px;
`;
const Item = styled.a`
  width: 30px;
  height: 30px;
  border-radius: 100%;
  border: 1px solid ${theme["blueGray-900"]};
  padding: 5px;
  cursor: pointer;
`;
const SocialMedia = () => {
  return (
    <React.Fragment>
      <Social className="socialMedia_icons">
        <Item href="#">
          <img src={instagram} alt="Instagram" />
        </Item>
        <Item href="#">
          <img src={facebook} alt="facebook" />
        </Item>
        <Item href="#">
          <img src={pinterest} alt="pinterest" />
        </Item>
        <Item href="#">
          <img src={linkedin} alt="linkedin" />
        </Item>
      </Social>
    </React.Fragment>
  );
};

export default SocialMedia;
