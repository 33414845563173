import React from "react";
import styled from "styled-components";

const Row = styled.div`
  ${(props) => props.backgound && `backgound: ${props.backgound};`}
  ${(props) =>
    props.rowMobile || props.row
      ? `grid-column: ${props.rowMobile || props.row};`
      : "width: 100%;"}
  ${(props) => props.padding && `padding: ${props.padding}`}
  ${(props) => props.padding && "box-sizing: border-box;"}
  overflow: hidden;
  margin-bottom: ${(props) => props.marginBottom};
  @media only screen and (min-width: 481px) {
    ${(props) => (props.row ? `grid-column: ${props.row};` : "width: 100%;")}
  }
`;

function Rows({
  children,
  row,
  className,
  backgound,
  padding,
  rowTable,
  rowMobile,
  marginBottom = "0.5em",
}) {
  return (
    <Row
      className={className}
      row={row}
      backgound={backgound}
      padding={padding}
      rowTable={rowTable}
      rowMobile={rowMobile}
      marginBottom={marginBottom}
    >
      {children}
    </Row>
  );
}

export default Rows;
