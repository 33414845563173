import React from "react";
import { Switch, Route } from "react-router-dom";

//Pages
import Home from "./pages/Home";
import Bio from "./pages/Bio";
import Colecciones from "./pages/Colecciones";
import ColeccionesItem from "./pages/ColeccionesItem";
import Eventos from "./pages/Eventos";
import EventosItem from "./pages/EventosItem";
import Contact from "./pages/Contact";


//components

import Header from "./components/header/Header";
import NavBar from "./components/NavBar";
import SideLetf from "./components/SideLetf";

const App = () => {
  return (
    <React.Fragment>
      <Header>
        <NavBar />
      </Header>
      <SideLetf>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/bio" exact component={Bio} />
          <Route path="/eventos" exact component={Eventos}/>
          <Route path="/contacto" exact component={Contact}/>
          <Route path="/eventos/:slug" component={EventosItem}/>
          <Route path="/colecciones/:slug" exact component={Colecciones}/>
          <Route path="/colecciones/:slug/:id" component={ColeccionesItem}/>
          {/* <Route path="*" component={NotFound} /> */}
        </Switch>
      </SideLetf>
    </React.Fragment>
  );
};

export default App;
