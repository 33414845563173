import React from 'react'
import { Link } from "react-router-dom";
import styled from "styled-components";
import { theme } from "../styles";

const ButtonCard = styled(Link)`
    background-color: ${theme["gray-300"]};
    border: 1px solid ${theme["gray-200"]};
    padding: .5em 2em;
    width: auto;
    text-align: center;
    margin: 1.5em 0;
    box-shadow: ${theme["boxShadow-lg"]};
    border-radius: 0.125em;
    font-weight: 600;
    font-size:1.125em;
    display: inline-block;
    text-decoration: none !important;

    &:hover {
      background-color: ${theme["gray-200"]};
      transition: 0.5s;
    }
`

const Button = ({route, cta}) => {
    return (
        <React.Fragment>
            <ButtonCard to={route}>{cta}</ButtonCard>
        </React.Fragment>
    )
}

export default Button
