import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { theme } from "../styles";
import {
  Form,
  Input,
  Textarea,
  Button,
  useForm,
  Captcha,
} from "../components/form/index";
import { Grid, Row } from "../components/grid/index";
import Precarga from "../components/Precarga";
import styled from "styled-components";
const Div = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  background: #fff;
  justify-content: center;
  align-item: center;
  padding: 5% 2%;
  svg {
    width: 15%;
  }
  .message {
    width: 100%;
    text-align: center;
    padding: 5% 10%;
  }
`;

function validate(value) {
  let errors = {};
  if (!value.email) {
    errors.email = true;
  } else if (!/\S+@\S+\.\S+/.test(value.email)) {
    errors.email = "Introduzca un email valido";
  }
  if (!value.name) {
    errors.name = true;
  }

  if (!value.comments) {
    errors.comments = true;
  }
  return errors;
}

const initialState = {
  name: "",
  email: "",
  comments: "",
};

function Contact() {
  const recaptcha = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isTrue, setIsTrue] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const [notSuccessful, setNotSuccessful] = useState(false);
  const { handleChange, handleError, values, errors } = useForm(
    submit,
    validate,
    initialState,
    isTrue
  );

  useEffect(() => {
    setIsLoading(false);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    handleError();
    if (values === null) recaptcha.current.reset();
  };

  const handleRecaptcha = (e) => {
    const captcha = recaptcha.current.getValue();
    if (!captcha.lenght) {
      setIsTrue(true);
      return;
    }
  };

  function submit() {
    axios
      .post(process.env.REACT_APP_DOMAIN + "contact", values)
      .then((res) => setSuccessful(true))
      .catch((err) => {
        setNotSuccessful(true);
        console.error(err);
      });
  }

  if (isLoading) {
    return <Precarga />;
  }
  switch (true) {
    case successful:
      return success();
    case notSuccessful:
      return notSuccess();
    default:
      return (
        <Form onSubmit={handleSubmit} noValidate>
          <Grid columnMobile="12">
            <Row row="1/-1" marginBottom="2em">
              <h1>Contacto</h1>
            </Row>
            <Row row="1/7" rowMobile="1/-1" rowTable="1/8">
              <Input
                value={values.name}
                onChange={handleChange}
                name="name"
                label="Nombre"
                type="text"
                fontColor={theme["gray-600"]}
                fontSize={theme["fontSize-sm"]}
                error={errors.name}
              />
            </Row>
            <Row row="7/13" rowMobile="1/-1">
              <Input
                value={values.email}
                onChange={handleChange}
                label="E-mail"
                type="text"
                name="email"
                fontColor={theme["gray-600"]}
                fontSize={theme["fontSize-sm"]}
                error={errors.email}
              />
            </Row>
            <Row row="1/-1">
              <Textarea
                value={values.comments}
                onChange={handleChange}
                label="Mensaje"
                name="comments"
                error={errors.comments}
              />
            </Row>
            <Row row="1/-1">
              <Captcha
                ref={recaptcha}
                sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                onChange={handleRecaptcha}
              />
            </Row>
            <Row row="1/-1">
              <Button
                title="Enviar"
                type="submit"
                fontSize={theme["fontSize-sm"]}
              />
            </Row>
          </Grid>
        </Form>
      );
  }

  function success() {
    return (
      <Div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <ellipse cx="256" cy="256" rx="256" ry="255.832" fill="#32bea6" />
          <path
            fill="#fff"
            d="M235.472 392.08l-121.04-94.296 34.416-44.168 74.328 57.904 122.672-177.016 46.032 31.888z"
          />
        </svg>
        <div className="message">
          <h2>Enviado con exito</h2>
        </div>
      </Div>
    );
  }

  function notSuccess() {
    return (
      <Div>
        <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M511.914 256c0-141.387-114.594-256-255.957-256C114.597 0 0 114.613 0 256s114.598 256 255.957 256c141.363 0 255.957-114.613 255.957-256zm0 0"
            fill="#f36986"
          />
          <path
            d="M413.855 54.504c34.133 43.5 54.493 98.332 54.493 157.922 0 141.383-114.598 256-255.957 256-59.582 0-114.403-20.363-157.899-54.504C101.352 473.637 174.176 512 255.957 512c141.363 0 255.957-114.613 255.957-256 0-81.797-38.355-154.633-98.059-201.496zm0 0"
            fill="#fc476e"
          />
          <path
            d="M272.21 299h-32.507c-6.437 0-11.656-5.219-11.656-11.66v-147.2c0-6.44 5.219-11.66 11.656-11.66h32.508c6.437 0 11.66 5.22 11.66 11.66v147.2c0 6.441-5.219 11.66-11.66 11.66zm0 0"
            fill="#ffd2e3"
          />
          <path
            d="M272.215 128.48h-21.067c6.438 0 11.657 5.22 11.657 11.66v147.2c0 6.441-5.22 11.66-11.657 11.66h21.067c6.437 0 11.656-5.223 11.656-11.66v-147.2c0-6.44-5.219-11.66-11.656-11.66zm0 0"
            fill="#ffbbd7"
          />
          <path
            d="M255.957 391.805c-15.414 0-27.91-12.5-27.91-27.914v-.008c0-15.414 12.496-27.914 27.91-27.914s27.914 12.5 27.914 27.914v.008c0 15.414-12.5 27.914-27.914 27.914zm0 0"
            fill="#ffd2e3"
          />
          <path
            d="M255.957 335.969c-2.246 0-4.43.273-6.523.773 12.27 2.942 21.39 13.973 21.39 27.145 0 13.168-9.12 24.203-21.39 27.14 2.093.504 4.277.778 6.523.778 15.418 0 27.914-12.5 27.914-27.918s-12.5-27.918-27.914-27.918zm0 0"
            fill="#ffbbd7"
          />
        </svg>
        <div className="message">
          <h2>
            Lo sentimos en este momento no podemos enviar tu mensaje por favor
            intente más tarde
          </h2>
        </div>
      </Div>
    );
  }
}

export default Contact;
