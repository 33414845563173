import { useState, useEffect } from "react";

const useForm = (callback, validate, state, recaptcha) => {
  const [values, setValues] = useState(state);
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const handleError = () => {
    setErrors(validate(values));
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && recaptcha) {
      callback();
      console.log("send");
    }
  }, [errors]);

  return {
    handleChange,
    handleError,
    values,
    errors,
  };
};

export default useForm;
