import axios from "axios";

export const getData = (path) => {
  try {
    return axios.get(`${process.env.REACT_APP_DOMAIN}/${path}`);
  } catch (e) {
    console.error(e);
  }
};

export const getPage = (path) => {
  return getData(`menu/${path}`);
};

export const getPageItem = (path) => {
  return getData(`articles/${path}`);
};
