import React from "react";
import styled from "styled-components";

const Textarea = styled.textarea`
  font-size: ${(props) => props.fontSize ?? `1em`};
  color: ${(props) => props.fontColor ?? `#777`};
  padding: ${(props) => props.padding ?? `0.625em 0.625em 0.625em 0.3125em`};
  display: block;
  width: 100%;
  border: none;
  border-bottom: 0.03125em solid ${(props) => props.borderColor ?? `#E0E0E0`};
  resize: none;
  &:focus {
    outline: none;
    color: ${(props) => props.fontColor ?? `#BDBDBD`};
  }
  &:focus ~ span::before {
    width: 100%;
  }

  ~ span {
    position: relative;
    display: block;
    width: 100%;
  }
  ~ span::before {
    content: "";
    height: 0.0625em;
    width: 0%;
    bottom: 0;
    position: absolute;
    background: ${(props) => props.focus};
    transition: 0.3s ease all;
    left: 0%;
  }
`;

const Label = styled.span`
  font-size: 0.875em;
  color: ${(props) => props.fontColor};
`;

const Error = styled.span`
  font-size: 0.875em;
  color: #F00;
`;

export default ({...props}) => {
  return (
    <React.Fragment>
      <Label>{props.error && <Error>*</Error>}{props.label}</Label>
      <Textarea {...props} />
      <span />
    </React.Fragment>
  );
};
