const media = {
  "small": "481px",
  "medium": "769px",
  "large": "1025px",
  "largeAlt": "1261px",
  "wide": "1441px",
}


export default media;
