import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(${(props) => props.mobile}, 1fr);
  gap: 0.5em;
  width: 100%;
  margin: 2em 0;

  @media only screen and (min-width: 481px) {
    grid-template-columns: repeat(
      ${(props) =>
        props.length >= props.column ? props.column : props.length},
      1fr
    );
  }
`;

const ColumnItems = styled.div`
  display: block;
  margin-bottom: 0.5em;
`;

const Masonry = ({ children, column, length, mobile }) => {
  const wrapper = {};
  const item = [];
  const getColumn = length >= column ? column : length;
  const _column = window.screen.width <= 480 ? mobile : getColumn;
  for (let i = 0; i < _column; i++) {
    wrapper[`column-${i}`] = [];

    for (let m = 0; m < Math.ceil(children.length / _column); m++) {
      wrapper[`column-${i}`].push(
        <ColumnItems className="column-items">
          {children[m * _column + i]}
        </ColumnItems>
      );
    }
  }

  for (let i = 0; i < column; i++) {
    item.push(<div className="columns">{wrapper[`column-${i}`]}</div>);
  }

  return (
    <Container column={column} length={length} mobile={mobile}>
      {item}
    </Container>
  );
};

export default Masonry;
