import React from "react";
import styled from "styled-components";

const Input = styled.input`
  font-size: ${(props) => props.fontSize};
  color: ${(props) => props.fontColor};
  padding: ${(props) => props.padding};
  display: block;
  width: 100%;
  border: none;
  border-bottom: 0.03125em solid ${(props) => props.borderColor};
  &:focus {
    outline: none;
    color: ${(props) => props.fontColor};
  }
  &:focus ~ span::before {
    width: 100%;
  }

  ~ span {
    position: relative;
    display: block;
    width: 100%;
  }
  ~ span::before {
    content: "";
    height: 0.0625em;
    width: 0%;
    bottom: 0;
    position: absolute;
    background: ${(props) => props.focus};
    transition: 0.3s ease all;
    left: 0%;
  }
`;

const Label = styled.span`
  font-size: 0.875em;
  color: ${(props) => props.fontColor};
`;
const Error = styled.span`
  font-size: 0.875em;
  color: #F00;
`;

export default ({
  label,
  type,
  name,
  placeholder,
  value,
  id,
  onChange,
  onBlur,
  error,
  fontSize = "1em",
  fontColor = "#777",
  focus = "#BDBDBD",
  borderColor="#E0E0E0",
  padding= "0.625em 0.625em 0.625em 0.3125em"
}) => {
  return (
    <React.Fragment>
      <Label>{error && <Error>*</Error>}{label}</Label>
      <Input
        id={id}
        type={type}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        fontSize={fontSize}
        fontColor={fontColor}
        focus={focus}
        borderColor={borderColor}
        padding={padding}
      />
      <span/>
      <Error>{error}</Error>
    </React.Fragment>
  );
};
