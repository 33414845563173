import React from "react";
import styled from "styled-components";

const Button = styled.button`
  background-color: ${(props) => props.background};
  color: ${(props) => props.fontColor};
  padding: 0.5em 2em;
  width: auto;
  text-align: center;
  margin: 1.5em 0;
  border: 0;
  border-radius: 0.125em;
  font-weight: 400;
  font-size: ${(props) => props.fontSize};
  display: inline-block;
  text-decoration: none !important;
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: ${(props) => props.hover};
    transition: 0.5s;
  }
  &:disabled {
    background: #777;
  }
`;

export default ({
  title,
  id,
  type,
  textColor,
  background = "#E0E0E0",
  hover = "#EEEEEE",
  fontColor = "#777",
  fontSize = "1em",
  onClick,
  disabled,
}) => {
  onClick = onClick || (() => {});
  return (
    <Button
      id={id}
      type={type}
      background={background}
      textColor={textColor}
      hover={hover}
      fontColor={fontColor}
      fontSize={fontSize}
      onClick={onClick}
      disabled={disabled}
    >
      {title}
    </Button>
  );
};
