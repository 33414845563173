import React from "react";
import styled from "styled-components";
import { theme, media } from "../../styles/index";

const MainHeader = styled.header`
  align-items: center;
  background: ${theme["white"]};
  box-shadow: ${theme["boxShadow-md"]};
  display: flex;
  flex-wrap: wrap;
  min-height: 65px;
  justify-content: space-between;
  transition: all 2s;
  width: 100%;

  @media only screen and (min-width: ${media["medium"]}) {
    box-shadow: ${theme["boxShadow-2xl"]};
    min-height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    width: 30%;
    overflow-y: auto;
  }
`;
const Header = (props) => {
  return (
    <React.Fragment>
      <MainHeader>{props.children}</MainHeader>
    </React.Fragment>
  );
};
export default Header;
