import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { media, theme } from "../styles";
import { getData } from "../utils";
// Componentes
import Logo from "./Logo";
import SocialMedia from "./SocialMedia.js";
// Imagenes
import Abrir from "../assets/images/open.svg";
import Cerrar from "../assets/images/close.svg";

const Logotype = styled.div`
  padding: 1rem;
  width: 40%;
  z-index: 9999;
  @media only screen and (min-width: ${media["medium"]}) {
    width: 100%;
    align-self: flex-start;
  }
`;

const IconMenu = styled.div`
  cursor: pointer;
  padding-right: 1rem;
  width: 2.25em;
  z-index: 9999;
  @media only screen and (min-width: ${media["medium"]}) {
    display: none;
  }
`;

const Nav = styled.nav`
  background: ${theme["white"]};
  box-shadow: ${theme["boxShadow-2xl"]};
  font-family: "Source Sans Pro", system-ui, sans-serif;
  min-height: 100%;
  padding-top: 90px;
  padding-left: 1.5rem;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 999;

  ul {
    list-style: none;
    width: 100%;

    li {
      width: 100%;
      width: 100%;
      transition: 1s;

      a {
        color: inherit;
        display: block;
        padding: 0.5rem;
        text-decoration: none !important;
        transition: 0.3s ease-in-out;
        svg {
          display: inline-block;
          margin-left: 0.5rem;
          width: 0.25rem;
          visibility: hidden;

          path {
            fill: #707070;
          }
        }

        &:hover {
          background: ${theme["gray-300"]};
          font-size: 1.02rem;
          color: 424242;
          font-weight: 600;

          svg {
            path {
              fill: #424242;
            }
          }
        }
      }

      ul {
        padding: 0.5rem 1rem 0;

        li {
          a {
            display: block;
            padding: 0.5rem;
            text-decoration: none;
          }
        }
      }
    }
  }

  @media only screen and (min-width: ${media["medium"]}) {
    position: relative;
    width: 100%;
    box-shadow: none;
    padding: 1rem;
    display: flex;
    align-self: flex-end;

    ul {
      li {
        a {
          svg {
            visibility: visible !important;
          }
        }
      }
    }
  }
`;

const NavBar = () => {
  const [items, setItems] = useState([]);
  const [open, setOpen] = useState(false);
  const [subMenu, setSub] = useState(false);

  useEffect(() => {
    async function _fecth() {
      const resp = await getData("menu");
      const pages = await resp.data;
      setItems(pages);
    }
    _fecth();
  }, []);

  const click = () => {
    setOpen(!open);
  };

  const clickSub = (item, e) => {
    if (item.length > 0) {
      e.preventDefault();
      setSub(!subMenu);
    }
    return;
  };

  const _className = !open ? " close" : "fade fadeSub";
  const sub = !subMenu ? " closeSub animation" : "closeSub fadeSub";
  const icon = !open ? Abrir : Cerrar;
  return (
    <Fragment>
      <Logotype>
        <Logo />
      </Logotype>

      <IconMenu className="iconMenu" onClick={click}>
        <img src={icon} alt={icon} />
      </IconMenu>

      <Nav className={_className}>
        <ul>
          {items &&
            items.map((primary, i) => (
              <>
                {primary.enable !== "0" && primary.title && (
                  <li key={i}>
                    <Link
                      to={
                        primary.slug === ("home" || "inicio")
                          ? "/"
                          : primary.children.length > 0
                          ? ""
                          : "/" + primary.slug
                      }
                      onClick={(e) => {
                        clickSub(primary.children, e);
                        click();
                      }}
                    >
                      {primary.title}
                      {primary.children.length > 0 && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 16 28.1"
                        >
                          <path d="M15.4 15.4l-12 12a2 2 0 01-2.8-2.7l10.7-10.6L.6 3.4A2 2 0 013.4.6l12 12a2 2 0 01.6 1.5 2 2 0 01-.6 1.3z" />
                        </svg>
                      )}
                    </Link>
                    {primary.children.length > 0 && (
                      <ul className={`${sub} ${_className}`}>
                        {primary.children.map((item, i) => (
                          <>
                            {item.enable !== "0" && item.title && (
                              <li key={i}>
                                <Link
                                  to={`/${primary.slug}/${item.slug}`}
                                  onClick={click}
                                >
                                  {item.title}
                                </Link>
                              </li>
                            )}
                          </>
                        ))}
                      </ul>
                    )}
                  </li>
                )}
              </>
            ))}
          <SocialMedia />
        </ul>
      </Nav>
    </Fragment>
  );
};

export default NavBar;
