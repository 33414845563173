import React from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

const Layout = ({ title, description, image, children }) => {
  const location = useLocation();
  return (
    <>
      <Helmet>
        <title>{`Gabriela Zerpa - ${title}`}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image || "/"} />
        <meta
          property=" og:url"
          content={process.env.REACT_DOMAIN + location.pathname}
        />
        <meta name="twitter:card" content={image || "/"} />
      </Helmet>
      {children}
    </>
  );
};

export default Layout;
