import React from "react";
import styled from "styled-components";
import { media } from "../styles/index";

const SideLeft = styled.div`
  width: 100%;
  position: relative;
  padding: 2rem 0;
  @media only screen and (min-width: ${media["medium"]}) {
    width: 70%;
    min-height: 100%;
    position: absolute;
    top: 0;
    right: 0;
  }
`;

const Container = styled.div`
  width: 95%;
  margin: 0 auto !important;
  display: flex;
  flex-wrap: wrap;
  @media only screen and (min-width: ${media["medium"]}) {
    .container {
      width: 90%;
    }
  }
`;
const SideLetf = ({ children }) => {
  return (
    <React.Fragment>
      <SideLeft>
        <Container>{children}</Container>
      </SideLeft>
    </React.Fragment>
  );
};

export default SideLetf;
