import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getPage } from "../utils";

import Layout from "../components/Layout";
import Card from "../components/Card";
import Masonry from "../components/Masonry";
import Precarga from "../components/Precarga"

const Colecciones = () => {
  const [page, setPage] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { slug } = useParams();

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      const resp = await getPage(`${slug}`);
      setPage(resp.data[0]);
      setIsLoading(false);
    };
    fetchData();
  }, [slug]);

  if (isLoading) {
    return <Precarga />;
  }

  return (
    <React.StrictMode>
      {page.title && (
        <Layout title={page.title} description={page.title} image={page.img}>
          <h1>{page.title}</h1>
          <Masonry column={page.column} length={page.articles.length} mobile={2}>
            {page.articles &&
              page.articles.map((item) => (
                <Card
                  key={item.id}
                  img={item.img.path}
                  alt={`${page.title}-${item.title}`}
                  title={item.title}
                  measure={item.measure}
                  technique={item.technique}
                  place={item.place}
                  enable={item.enable}
                  link={`/colecciones/${slug}/${item.slug}`}
                />
              ))}
          </Masonry>
        </Layout>
      )}
    </React.StrictMode>
  );
};

export default Colecciones;
