import React from "react";
import styled from "styled-components";
import { theme } from "../styles";

import Button from "./Button"

const ItemImage = styled.div`
  border: 1px solid ${theme["gray-200"]};
  background: ${theme["white"]};
  box-shadow: ${theme["boxShadow-lg"]};
  font-size: ${theme["fontSize-xs"]};
  overflow: hidden;
  border-radius: 0.25em;
  .item-info {
    padding: 1em;
    box-sizing: border-box;
    font-family: "Source Sans Pro", system-ui, sans-serif;
  }
`;

const Card = ({ img, alt, title, measure, technique, place, enable, link }) => {
  return (
    <React.Fragment>
      <ItemImage enable={enable} className="fade">
          <img src={img} alt={alt} />
          <div className="item-info">
            {title && <h3>{title}</h3>}
            {measure && <p>{measure} </p>}
            {technique && <p>{technique}</p>}
            {place && <p>{place}</p>}
            {enable && <Button route={link} cta="Ver más"/>}
          </div>
      </ItemImage>
    </React.Fragment>
  );
};

export default Card;
