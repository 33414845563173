import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { theme } from "../styles/index";
import { getPage } from "../utils";

import Layout from "../components/Layout";
import Precarga from "../components/Precarga"

const About = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 10px;
  align-items: center;
  .profile {
    grid-column: 5/9;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .image-profile {
      border: 1px solid ${theme["gray-200"]};
      background: ${theme["white"]};
      box-shadow: ${theme["boxShadow-lg"]};
      font-size: ${theme["fontSize-xs"]};
      overflow: hidden;
      border-radius: 50%;
      padding: 0.3em;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 2em;

      img {
        border-radius: 50%;
      }
    }
    .title {
      width:100%
      margin-bottom: 2em;
      h3 {
        font-size: 1.17rem;
        font-family: "Source Sans Pro";
        font-weight: normal;
        font-display: swap;
        font-style: normal;
      }
    }
  }
  .content {
    grid-column: 1/-1;
  }

  .galeria {
    grid-column: 1/-1;
    display: flex;
    justify-content: space-evenly;
  }
`;

const Bio = () => {
  const [page, setPage] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const fetch = async () => {
      const resp = await getPage("bio");
      setPage(resp.data[0]);
      setIsLoading(false);
    };
    fetch();
  }, []);

  if (isLoading) {
    return <Precarga />;
  }

  return (
    <React.Fragment>
      {page.title && (
        <Layout
          title={page.articles[0].title}
          description={`${page.articles[0].title} ${page.title}`}
          image={page.articles[0].img.path}
        >
          {page.articles &&
            page.articles.map((item, i) => (
              <About className="fade" key={i}>
                <div className="profile">
                  <div className="image-profile">
                    <img src={item.img.path} alt={item.title} />
                  </div>
                  <div className="title">
                    <h1>{item.title}</h1>
                  </div>
                </div>
                <div
                  className="content"
                  dangerouslySetInnerHTML={{ __html: item.content }}
                />
              </About>
            ))}
        </Layout>
      )}
    </React.Fragment>
  );
};

export default Bio;
