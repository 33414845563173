import React from "react";
import styled from "styled-components";

const Form = styled.form`
  width: 100%;
  padding: 2em;
  background: ${props => props.background}
`;

export default ({
  onSubmit,
  id,
  children,
  background = "#FFFF",
  padding = "",
}) => {
  return (
    <Form onSubmit={onSubmit} id={id} background={background} padding={padding}>
      {children}
    </Form>
  );
};
