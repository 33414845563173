import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getPageItem } from "../utils";

//Styles
import styled from "styled-components";
import { theme } from "../styles";

//components
import Layout from "../components/Layout";
import Masonry from "../components/Masonry";
import Precarga from "../components/Precarga";

const Div = styled.div`
  h1,
  .content {
    margin-bottom: 1em;
    display: inline-block;
  }
  .item-image {
    border: 1px solid ${theme["gray-200"]};
    background: ${theme["white"]};
    box-shadow: ${theme["boxShadow-lg"]};
    overflow: hidden;
    border-radius: 0.25em;
  }
`;

const EventosItem = () => {
  const [item, setItem] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { slug } = useParams();

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      const resp = await getPageItem(slug);
      setItem(resp.data[0]);
      setIsLoading(false);
    };
    fetchData();
  }, [slug]);

  if (isLoading) {
    return <Precarga />;
  }
  return (
    <React.Fragment>
      {item.title && (
        <Layout
          title={item.title}
          description={item.title}
          image={item.img.path}
        >
          <Div className="fade">
            <h1>{item.title}</h1>
            <div className="item-info">
              {item.measure && <p>{item.measure} </p>}
              {item.technique && <p>{item.technique}</p>}
              {item.place && <p>{item.place}</p>}
            </div>
            {item.content && (
              <div
                className="content"
                dangerouslySetInnerHTML={{ __html: item.content }}
              />
            )}
          </Div>
          {Array.isArray(item.gallery) && item.gallery.length > 0 && (
            <Masonry
              column={2}
              length={item.gallery.length}
              mobile={item.gallery.length < 2 ? item.gallery.length : 2}
            >
              {item.gallery.map((item, i) => (
                <div key={i} className="item-image">
                  <img src={item.path} alt={item.title} />
                </div>
              ))}
            </Masonry>
          )}
        </Layout>
      )}
    </React.Fragment>
  );
};

export default EventosItem;
