import React, { useState, useEffect } from "react";
import { getData } from "../utils";

//Components
import Masonry from "../components/Masonry";
import Layout from "../components/Layout";
import CardHover from "../components/CardHover";
import Precarga from "../components/Precarga"

function Home() {
  const [pages, setPages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    const fetch = async () => {
      const res = await getData("all-menu");
      const image = res.data.filter((item) => item.img !== null);
      setPages(image);
      setIsLoading(!true);
    };
    fetch();
  }, []);

  if (isLoading) {
    return <Precarga />;
  }
  
  return (
    <React.Fragment>
      {pages[1] && (
        <Layout
          title={pages[0].title}
          description="Site de arte y diseño"
          image={pages[1].img.path}
        >
          <Masonry column={4} length={pages.length} mobile={2}>
            {pages &&
              pages.map((item, i) => (
                <>
                  {item.img && item.img.path && (
                    <CardHover
                      title={item.title}
                      image={item.img.path}
                      alt={item.title}
                      route={
                        item.parent_id === "4"
                          ? `/colecciones/${item.slug}`
                          : item.slug
                      }
                    />
                  )}
                </>
              ))}
          </Masonry>
        </Layout>
      )}
    </React.Fragment>
  );
}

export default Home;
